/*
 * @Description:
 * @Author: yanmin
 * @Date: 2021-06-23 17:20:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-04-15 10:49:32
 * @FilePath: \vue-car2\src\store\modules\user.js
 */
import api from '@/api'
// import { resetRouter } from '@/router'
export default {
    namespaced: true,
    state: {

    },
    mutations: {},
    actions: {}
}