<!--
 * @Author: yanmin
 * @Description: 
 * @Date: 2022-04-14 15:59:14
 * @LastEditTime: 2023-07-28 17:24:10
 * @FilePath: \yf_car\src\components\notorder.vue
-->
<!--  -->
<template>
    <div v-if="isordershow">
        <div class="g-white-box g-tips-box"><img src="../assets/images/tips-img-notorder.png" class="u-tips-img" />
            <h3 class="u-tips-h3">未查到订单</h3>
            <p class="u-tips-p">{{npaymentfont}}</p>
            <span
            style="width:100%;background:#fff; display:-webkit-box; padding:10px 12px; box-sizing:border-box; justify-content:center; align-items:center; overflow:hidden; border-radius:4px;"
            v-if="smartpark_id!==''">
            <!-- <h3 style="font-size:16px;height:.5rem; line-height:.5rem; text-align: center; font-weight:normal; -webkit-box-flex:1;color: #888888c7;">如车牌有误,请使用车牌支付
            </h3> -->
            </span>
            <!-- <div class="btn-notorder">
                <a style=" width:2.4rem; height:.7rem; line-height:.7rem; color:#fff; font-size:.22rem; margin:0; margin-bottom: 0.2rem; border-radius:4px;"
                :href="`${this.baseUrl}prepay?smartpark_id=${this.smartpark_id}`"
                class="g-btn f-btncolor-blue">车牌支付</a>
                <div style=" width:2.4rem; height:.7rem; line-height:.7rem; color:#fff; font-size:.22rem; margin:0; border-radius:4px;"
                  @click="flushed"
                class="g-btn f-btncolor-blue">刷新</div>
            </div> -->
        </div>
        
    </div>
</template>

<script>
    export default {
        components: {},
        props: [
            "isordershow",
            "npaymentfont",
            "smartpark_id"
        ],
        data() {
            return {
                baseUrl: process.env.VUE_APP_BASE_URL
            };
        },
        computed: {},
        watch: {},
        methods: {
          noPayTest() {
            this.$emit('on-pay')
          },
          flushed() {
            this.$emit('on-fresh')
          }
        },
        created() {

        },
        mounted() {

        },
        beforeCreate() {},
        beforeMount() {},
        beforeUpdate() {},
        updated() {},
        beforeDestroy() {},
        destroyed() {},
        activated() {},
    }
</script>
<style lang='scss' scoped>
.btn-notorder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>