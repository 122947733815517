import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = []

const files = require.context('./modules', true, /\.js$/)

files.keys().forEach(val => {
  routes.push(...files(val).default)
});

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes
})

export default router