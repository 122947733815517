/*
 * @Author: yanmin
 * @Description:
 * @Date: 2022-04-14 09:01:46
 * @LastEditTime: 2024-03-29 16:04:21
 * @FilePath: \vue-car2\src\permission.js
 */
import router from './router'

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
