/*
 * @Author: yanmin
 * @Description: 订单-Orders
 * @Date: 2022-04-14 09:01:46
 * @LastEditTime: 2023-12-04 16:26:35
 * @FilePath: \yf_car\src\api\modules\orders\base.js
 */
export default request => ({
  //扫码入场: 无牌车 车主 扫码入场
  scanEntryByNoPlate(data = {}, url = '/v1.orders/scanEntryByNoPlate', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  //扫描出场: 扫码 获取 出场停车订单
  scanGetExportConsume(data = {}, url = '/v1.orders/scanGetExportConsume', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 根据 车牌后几位 查询 园区内 在场车牌列表
  getCarNumberListInStoping(data = {}, url = '/v1.orders/getCarNumberListInStoping', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 根据 车牌号 获取 停车订单信息
  getOrdersInfoByCarNumber(data = {}, url = '/v1.orders/getOrdersInfoByCarNumber', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 根据 停车订单id 获取 停车订单信息
  getConsumeInfo(data = {}, url = '/v1.orders/getConsumeInfo', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 获取 停车订单的 在线支付信息
  getPayInfo(data = {}, url = '/v1.orders/getPayInfo', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 获取园区信息
  getSmartparkId(data = {}, url = '/v1.pub/getSmartparkId', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 获取园区appid
  getAppidData(data = {}, url = '/v1.pub/getAppId', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 获取场地名称
  getLocalInfo(data = {}, url = '/v1.pub/getLocalInfo', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 标记无牌车出场 /{{app_h5}}v1.orders/notCarNumberExport
  notCarNumberExport(data = {}, url = '/v1.orders/notCarNumberExport', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 临时车牌/{{app_h5}}v1.orders/notCarNumberExport
  getCarOwnerHisTempCarNumber(url = '/v1.orders/getCarOwnerHisTempCarNumber', method = 'POST') {
    return request({
      url,
      method,
    })
  },
  // 用户输入车牌领取公司券
  carClaimCouponByCode(data = {}, url = '/v1.orders/carClaimCouponByCode', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 静态二维码的领券接口
  carClaimCouponByCodeStatic(data = {}, url = '/v1.orders/carClaimCouponByCodeStatic', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 用户输入车牌领取公司券
  cancePay(data = {}, url = '/v1.orders/cancePay', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 自动领券
  claimCouponAutoByCode(data = {}, url = '/v1.orders/claimCouponAutoByCode', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 获取优惠券的信息
  getCompanyCouponPoolInfoByCode(data = {}, url = '/v1.orders/getCompanyCouponPoolInfoByCode', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
  // 落地页绑定车牌
  carReimbursementRuleBindCarNumber(data = {}, url = '/v1.orders/carReimbursementRuleBindCarNumber', method = 'POST') {
    return request({
      url,
      method,
      data,
    })
  },
})
