<!--
 * @Author: yanmin
 * @Description:  接收参数，是否显示loading层
 * @Date: 2022-04-14 16:00:24
 * @LastEditTime: 2022-04-14 16:00:25
 * @FilePath: \vue-car2\src\components\loadingbox.vue
-->
<!--  -->
<template>
    <div class="g-loading-box" :class="{floadinghide:!isloading}"></div>
</template>

<script>
    export default {
        components: {},
        props: [
            "isloading", //接收参数，是否显示loading层
        ],
        data() {
            return {

            };
        },
        computed: {},
        watch: {},
        methods: {

        },
        created() {

        },
        mounted() {

        },
        beforeCreate() {},
        beforeMount() {},
        beforeUpdate() {},
        updated() {},
        beforeDestroy() {},
        destroyed() {},
        activated() {},
    }
</script>
<style lang='scss' scoped>

</style>