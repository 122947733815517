/*
 * @Author: yanmin
 * @Description: 
 * @Date: 2022-04-14 19:13:27
 * @LastEditTime: 2022-04-14 19:20:03
 * @FilePath: \vue-car2\src\api\modules\index.js
 */
export default request => ({
    /**
     * @description 跳转到三方授权登录地址
     * @param {Number} passageway_id 通道id
     * @param {Number} consume_id 停车单id
     * @param {Number} smartpark_id 园区id
     * @param {string} from 三方类型（wx/ali 微信/支付宝）
     * @param {string} h5_url 登录后跳回页面（跳回时 url 上 带了 token）
     * @returns 返回token信息
     */
    redirectOauth(params = {}, url = '/v1.oauth/redirectOauth', method = 'get') {
        return request({
            url,
            method,
            params
        })
    },
    /**
     * @description 获取当前已登录车主信息
     * @returns 获取用户信息
     */
    getCarOwnerInfo(data = {}, url = '/v1.pub/getCarOwnerInfo', method = 'POST') {
        return request({
            url,
            method,
            data
        })
    },
    /**
     * @description 获取 停车场的 物业电话号码
     * @param {Number} passageway_id 通道id
     * @param {Number} consume_id 停车场id
     * @param {Number} smartpark_id 园区id
     * @param {string} consume_id 停车订单id
     * @returns 返回停车场的 物业电话号码
     */
    getPhone(data = {}, url = '/v1.pub/getPhone', method = 'POST') {
        return request({
            url,
            method,
            data
        })
    },
    // 获取车辆的全部优惠券
    getCarCouponListOnOrders(data = {}, url = '/v1.orders/getCarCouponListOnOrders', method = 'POST') {
        return request({
            url,
            method,
            data
        })
    }
})