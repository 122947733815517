/*
 * @Date         : 2022-06-20 09:13:22
 * @Author       : ym
 * @LastEditors: yanminw 2398988384@qq.com
 * @LastEditTime: 2024-04-17 11:17:50
 * @description  :
 * @FilePath: \yf_car\src\router\modules\record.js
 */
/*
 * @Author: yanmin
 * @Description:停车场出入场路由
 * @Date: 2022-04-14 09:01:46
 * @LastEditTime: 2022-04-15 15:44:56
 * @FilePath: \vue-car2\src\router\modules\record.js
 */
const prepay = () => import('@/views/prepay')
const GuardPayment = () => import('@/views/guardPayment')
const leave = () => import('@/views/leave')
const Pay = () => import('@/views/pay')
const join = () => import('@/views/join')
const claimCoupon = () => import('@/views/claimCoupon')
const result = () => import('@/views/result')
const carReimbursementRule = () => import('@/views/carReimbursementRule')
const rules = () => import('@/views/rules')
const recordRouters = [
  {
    path: '/prepay',
    name: '预支付',
    component: prepay,
    meta: {
      title: '预支付',
    },
  },
  {
    path: '/guardPayment',
    name: '停车费用支付',
    component: GuardPayment,
    meta: {
      title: '停车费用支付',
    },
  },
  {
    path: '/leave',
    name: '出场',
    component: leave,
    meta: {
      title: '出场',
    },
  },
  {
    path: '/pay',
    name: '支付成功',
    component: Pay,
    meta: {
      title: '支付成功',
    },
  },
  {
    path: '/',
    redirect: '/join',
  },
  {
    path: '/join',
    name: '无牌车入场',
    component: join,
    meta: {
      title: '无牌车入场',
    },
  },
  {
    path: '/claim_coupon',
    name: '领取券',
    component: claimCoupon,
    meta: {
      title: '领取券',
    },
  },
  {
    path: '/result',
    name: '领券状态',
    component: result,
    meta: {
      title: '领券状态',
    },
  },
  {
    path: '/car_reimbursement_rule',
    name: '领券状态',
    component: carReimbursementRule,
    meta: {
      title: '领券状态',
    },
  },
  {
    path: '/rules',
    name: '绑定状态',
    component: rules,
    meta: {
      title: '绑定状态',
    },
  },
]

export default recordRouters
