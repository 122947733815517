/*
 * @Description:
 * @Author: yanmin
 * @Date: 2020-07-29 18:05:59
 * @LastEditors: Dwyanewang
 * @LastEditTime: 2021-06-23 17:59:06
 */
// import { Message } from 'element-ui'

/**
 * @description 接口请求返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 * @param {Number} code 状态码
 */
export function response(data = {}, msg = '', code = 0, status = 200) {
    return [status, {
        code,
        msg,
        data
    }]
}

/**
 * @description 接口请求返回 正确返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 */
export function responseSuccess(data = {}, msg = '成功') {
    return response(data, msg)
}

/**
 * @description 接口请求返回 错误返回
 * @param {Any} data 返回值
 * @param {String} msg 状态信息
 * @param {Number} code 状态码
 */
export function responseError(
    data = {},
    msg = '请求失败',
    code = 500,
    status = 200
) {
    return response(data, msg, code, status)
}

/**
 * @description 记录和显示错误
 * @param {Error} error 错误对象
 */
export function errorLog(error) {
    // // 添加到日志
    // store.dispatch("d2admin/log/push", {
    //   message: "数据请求异常",
    //   type: "danger",
    //   meta: {
    //     error
    //   }
    // });
    // 打印到控制台
    if (process.env.NODE_ENV === 'dev') {
        console.log('%c>>>>>> Error >>>>>>', 'color:red')
        console.log(`%c${error}`, 'color: red')
    }
    // 显示提示
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 1000
    // })
}

/**
 * @description 创建一个错误
 * @param {String} msg 错误信息
 */
export function errorCreate(msg) {
    const error = new Error(msg)
    errorLog(error)
        // throw error;
}