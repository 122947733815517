/*
 * @Date         : 2022-06-20 09:13:22
 * @Author       : ym
 * @LastEditors: ym
 * @LastEditTime: 2024-01-16 10:27:11
 * @description  : 
 * @FilePath: \yf_car\src\main.js
 */
/*
 * @Author: yanmin
 * @Description:  主入口
 * @Date: 2022-04-14 09:01:46
 * @LastEditTime: 2022-05-26 17:38:32
 * @FilePath: \car2-h5-vue\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import rem from '@/utils/useRem'
// import VConsole from 'vconsole'
// 88
//引入vant
import Vant from 'vant'
import 'vant/lib/index.css'
import {
    Lazyload,
    Toast
} from 'vant';
import VueAnimateNumber from 'vue-animate-number'
Vue.use(Vant)
Vue.use(Lazyload).use(rem).use(Toast).use(VueAnimateNumber)
    // new VConsole()
    // import 'lib-flexible';
    // import 'postcss-pxtorem' //导入rem转换工具
import './assets/style/index.scss'
import './assets/style/base.css'
import './assets/style/payment.css'
// import './assets/style/keyboard.css'
// 引用公共样式
import footdiv from '@/components/footdiv'
import loadingbox from '@/components/loadingbox'
import notorder from '@/components/notorder'
import scancode from '@/components/scancode'
Vue.component('footdiv', footdiv)
Vue.component('loadingbox', loadingbox)
Vue.component('notorder', notorder)
Vue.component('scancode', scancode)
import './permission' // permission control
import {
    browserName
} from '@/utils/base'
import './icons';

//解决[WDS] Disconnected!报错
if (module.hot) {
    module.hot.accept();
}
// console.log('环境名称', browserName())

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')