/*
 * @Author: yanmin
 * @Description: rem设置
 * @Date: 2022-04-14 17:49:27
 * @LastEditTime: 2022-04-14 17:49:27
 * @FilePath: \vue-car2\src\utils\useRem.js
 */
rem();

function rem() {
    //长宽占位 rem算法, 根据root的rem来计算各元素相对rem, 默认html 320/20 = 16px */
    var w = document.documentElement.offsetWidth;
    document.documentElement.style.fontSize = w / 7.2 + 'px';
    window.onresize = function() {
        //长宽占位 rem算法, 根据root的rem来计算各元素相对rem, 默认html 320/20 = 16px */
        var w = document.documentElement.offsetWidth;
        document.documentElement.style.fontSize = w / 7.2 + 'px';
    }
}