/*
 * @Description:
 * @Author: Dwyanewang
 * @Date: 2021-06-23 10:47:43
 * @LastEditors: Dwyanewang
 * @LastEditTime: 2021-06-23 17:18:48
 * @FilePath: \admin-vuee:\Dwyanewang\Projects\MyProjects\dw-admin-demo\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const path = require('path')
const files = require.context('./modules', false, /\.js$/)
const modules = {}

files.keys().forEach(key => {
    modules[path.basename(key, '.js')] = files(key).default
})
export default new Vuex.Store({
    modules,
    getters
    // strict: process.env.NODE_ENV !== "production"
})