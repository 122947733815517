<!--
 * @Author: yanmin
 * @Description: //底部通用层
 * @Date: 2022-04-14 16:02:25
 * @LastEditTime: 2023-07-26 15:38:53
 * @FilePath: \yf_car\src\components\footdiv.vue
-->
<!--  -->
<template>
  <!-- <p class="u-call-wy" > -->
    <a
        class="call-btn"
        :href="`${baseURl}audiovideo/index?passageway_id=${passagewayId}&show_header=2`"
        v-if="name!='ali'"
      >
        <img src="@/assets/images/video.png" />
        <p>呼叫门卫</p>
      </a>
    <!-- 物业联系电话: <a :href="'tel:' + phone">{{ phone }} (点击拨号) </a> -->
     <!-- <a :href="`${baseURl}audiovideo/index?passageway_id=${passagewayId}`">呼叫门卫</a> -->
  <!-- </p> -->
</template>

<script>
import api from '@/api'
import { getToken, getSmarkId, getPassagewayId } from '@/utils/auth'
import { Toast } from 'vant'
import { getNewObj } from '@/utils/base'
import { browserName } from '@/utils/base'
const {
  index: {
    // getCarOwnerInfo,
    getPhone,
  },
} = api
export default {
  components: {
    Toast,
  },
  props: {
    passagewayId: null
  },
  data() {
    return {
      phone: '',
      consume_id: '',
      baseURl: process.env.VUE_APP_BASE_VIDEO_URL,
      // baseURl: 'http://192.168.80.89:8081/',
      name: browserName()
    }
  },
  computed: {},
  watch: {},
  methods: {
    phoneFn() {
      let obj = {
        smartpark_id: getSmarkId(),
        passageway_id: getPassagewayId(),
        consume_id: this.consume_id,
      }
      let newObj = getNewObj(obj)
      let len = Object.keys(newObj).length
      if (len && getToken()) {
        getPhone(newObj).then(res => {
          if (res.code === 1) {
            this.phone = res.data.phone
          } else {
            Toast.fail(res.data.msg)
          }
        })
      }
    },
    init() {
      // window.open(`${this.baseURl}audiovideo/index?passageway_id=${this.passagewayId}`)
      // window.location.href = `${this.baseURl}audiovideo/index?passageway_id=${this.passagewayId}`
      // this.$router.push({path: this.baseURl+'audiovideo/index', query: {passageway_id: this.passagewayId}})
    }
  },
}
</script>
<style lang="scss" scoped>
  .call-btn {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 1rem auto 0;
    background: -webkit-linear-gradient(top, #3992ff 0%, #4087f2 100%);
    background: linear-gradient(180deg, #3992ff 0%, #4087f2 100%);
    box-shadow: #b6d1f8 0 0 0.37333rem;
    border-radius: 50%;
    text-align: center;
    img {
      width: 0.8rem;
      height: 0.78rem;
      margin-top: 0.4rem;
      vertical-align: top;
    }
    p {
      margin-top: 0.1rem;
      font-size: 0.3rem;
      color: #ffffff;
    }
  }
</style>
