/*
 * @Author: yanmin
 * @Description:
 * @Date: 2022-04-14 09:01:46
 * @LastEditTime: 2022-04-15 10:17:00
 * @FilePath: \vue-car2\src\utils\base.js
 */
/**
 * 通用js方法封装处理
 */

// 日期格式化
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
            // Note: getDay() returns 0 on Sunday
        if (key === 'a') {
            return ['日', '一', '二', '三', '四', '五', '六'][value]
        }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

//时间转换
export function getTime(time) {
    let dateTime
    let yy = time.getFullYear()
    let mm = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth()
    let dd = time.getDate() < 10 ? '0' + time.getDate() : time.getDate()
    let hh = time.getHours() < 10 ? '0' + time.getHours() : time.getHours()
    let mf = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes()
    dateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf
    return dateTime
}

//剔除空对象
export function removeObjNull(val) {
    Object.keys(val).map(key => {
        if (!val[key]) {
            delete val[key];
        }
    })
    return val
}
// 判断是否是微信还是支付宝
export function browserName() {
    var ua = navigator.userAgent.toLowerCase();
    let name = ''
    if (ua.indexOf('micromessenger') !== -1) {
        name = 'wx'
    } else if (ua.indexOf('alipayclient') !== -1) {
        name = 'ali'
    } else {
        name = 'others'
    }
    return name
}
// 默认解析地址
export function urlEncode(param, idx, key, encode) {
    // console.log('idx', idx)
    if (param == null) return '';
    var paramStr = '';
    var t = typeof(param);
    if (t == 'string' || t == 'number' || t == 'boolean') {
        var one_is = idx < 3 ? '?' : '&';
        paramStr += one_is + key + '=' + ((encode == null || encode) ? encodeURIComponent(param) : param);
    } else {
        for (var i in param) {
            var k = key == null ? i : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
            idx++
            paramStr += urlEncode(param[i], idx, k, encode);
        }
    }
    return paramStr;
}
//  默认对象解析
export function urlParse(address) {
    let url = address ? address : window.location.search;
    let obj = {};
    let reg = /[?&][^?&]+=[^?&]+/g;
    let arr = url.match(reg);
    // ['?id=12345', '&a=b']
    if (arr) {
        arr.forEach((item) => {
            let temArr = item.substring(1).split('=');
            let key = decodeURIComponent(temArr[0]);
            let value = decodeURIComponent(temArr[1]);
            obj[key] = value;
        });
    }
    return obj;
}

//对象删除
export function getNewObj(data) {
    let obj = {}
    Object.keys(data).map(ele => {
        if (data[ele]) {
            obj[ele] = data[ele]
        }
    })
    return obj
}