<!--
 * @Author: yanmin
 * @Description: 扫码提示通用层
 * @Date: 2022-04-14 16:01:24
 * @LastEditTime: 2022-04-15 13:05:26
 * @FilePath: \vue-car2\src\components\scancode.vue
-->
<!--  -->
<template>
    <div class="g-scancode-box" v-if="scancodeshow"><img src="../assets/images/tips-img-sm.png" />
        <h1>请用微信或者支付宝扫码</h1>
    </div>
</template>

<script>
    export default {
        components: {},
        props: [
            "scancodeshow",
        ],
        data() {
            return {

            };
        },
        computed: {},
        watch: {},
        methods: {

        },
        created() {

        },
        mounted() {

        },
        beforeCreate() {},
        beforeMount() {},
        beforeUpdate() {},
        updated() {},
        beforeDestroy() {},
        destroyed() {},
        activated() {},
    }
</script>
<style lang='scss' scoped>

</style>