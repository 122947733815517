/*
 * @Author: yanmin
 * @Description: 
 * @Date: 2022-04-14 09:01:46
 * @LastEditTime: 2024-01-16 09:47:09
 * @FilePath: \yf_car\src\utils\auth.js
 */
import Cookies from 'js-cookie'
const TokenKey = 'token'
const SmartparkId = 'Smarkpark-Id'
const ParklogId = 'Parkinglot-Id'
const InfoGatewayId = 'InfoGateway-Id'
const OutGatewayId = 'OutoGateway-Id'
const AppId = 'appid'
const useCarId = 'useCar-Id'
    // 常用车辆号码
export function getUseCarId() {
    return localStorage.getItem(useCarId);
}

export function setUseCarId(id) {
    return localStorage.setItem(useCarId, id);
}

export function removeUseCarId() {
    return localStorage.removeItem(useCarId);
}
//token
export function getToken() {
    // return localStorage.getItem(TokenKey)
    return localStorage.getItem(TokenKey + (getAppid() ? ':' : '') + getAppid())
}

export function setToken(token, id) {
    // return localStorage.setItem(TokenKey, token)
    return localStorage.setItem(TokenKey + (id ? ':' : '') + id, token)
}

export function removeToken() {
    return localStorage.removeItem(TokenKey + (getAppid() ? ':' : '') + getAppid())
}

//园区ID
export function getSmarkId() {
    return localStorage.getItem(SmartparkId);
}

export function setSmarkId(id) {
    return localStorage.setItem(SmartparkId, id);
}

export function removeSmarkId() {
    return localStorage.removeItem(SmartparkId);
}

//停车场ID
export function getPassagewayId() {
    return localStorage.getItem(ParklogId);
}

export function setPassagewayId(id) {
    return localStorage.setItem(ParklogId, id);
}

export function removePassagewayId() {
    return localStorage.removeItem(ParklogId);
}
export function getAppid() {
    return localStorage.getItem(AppId);
}
export function setAppid(id) {
    return localStorage.setItem(AppId, id)
}
export function removeAppid(id) {
    return localStorage.setItem(AppId)
}