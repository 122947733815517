/*
 * @Description:
 * @Author: Dwyanewang
 * @Date: 2021-06-23 17:19:56
 * @LastEditors: Dwyanewang
 * @LastEditTime: 2021-06-23 17:48:44
 * @FilePath: \admin-vuee:\Dwyanewang\Projects\MyProjects\dw-admin-demo\src\store\getters.js
 */
export default {
    // userName: state => state.user.name,
}