/*
 * @Description:axios配置
 * @Author: yanmin
 * @Date: 2020-07-29 17:44:35
 * @LastEditors: ym
 * @LastEditTime: 2023-08-09 10:36:02
 */
import axios from 'axios'
import router from '../router'
import {
    errorLog,
    errorCreate
} from './axios.tools'
import {
    getToken,
    removeToken
} from '../utils/auth'
import {
    Dialog,
    Toast
} from 'vant'

/**
 * @description 创建请求实例
 */
const createService = () => {
    const service = axios.create()
        // request请求拦截器
    service.interceptors.request.use(
            config => config,
            error => {
                // 发送失败
                return Promise.reject(error)
            }
        )
        // response响应拦截器
    service.interceptors.response.use(
        response => {
            // dataAxios 是 axios 返回数据中的 data
            const dataAxios = response.data
                // 这个状态码是和后端约定的
            const {
                code
            } = dataAxios
            // 根据 code 进行判断
            if (code === undefined) {
                // 如果没有 code 代表这不是项目后端开发的接口 比如可能是 D2Admin 请求最新版本
                return dataAxios
            } else {
                // 有 code 代表这是一个后端接口 可以进行进一步的判断
                switch (code) {
                    case 1:
                        // [ 示例 ] code === 1 代表正常
                        return dataAxios
                    default:
                        // [ 示例 ] 其它和后台约定的 code
                        errorCreate(
                                `[ code: ${code} ] ${dataAxios.msg}: ${response.config.url}`
                            )
                            // Dialog({
                            //         message: dataAxios.msg,
                            //         showConfirmButton: true,
                            //         showCancelButton: false
                            //     })
                            // Toast(dataAxios.msg)
                        return dataAxios
                            // return Promise.reject(dataAxios.msg)
                }
            }
        },
        error => {
            let { message } = error;
            if (message == "Network Error") {
                message = "网络请求异常，请检查手机网络后重试";
            } else if (message.includes("timeout")) {
                message = "系统接口请求超时";
            } else if (message.includes("Request failed with status code")) {
                message = "系统接口" + message.substr(message.length - 3) + "异常";
            }
            Dialog({
                message: message,
            })
            return Promise.reject(message)
        }
    )
    return service
}

/**
 * @description 创建请求方法
 * @param {Object} service axios 实例
 */
const createRequest = service => {
    return config => {
        config = {
                ...config,
                timeout: 5000,
                baseURL: process.env.VUE_APP_BASE_API,
                headers: {}
            }
            // getToken() && (config.headers.Authorization = `Bearer ${getToken()}`)
            // if (config.url == '/v1.helper/uploadImg' || config.url == '/v1.helper/ocrPlate') {
            //     config.headers['Content-Type'] = 'multipart/form-data;'
            // }
        getToken() && (config.headers['token'] = getToken())
        return service(config)
    }
}

// 用于网络请求的实例和请求方法
const service = createService()
export const request = createRequest(service)