/*
 * @Description:
 * @Author: Dwyanewang
 * @Date: 2020-07-29 18:43:07
 * @LastEditors: Dwyanewang
 * @LastEditTime: 2021-06-24 10:03:24
 */

import { request } from './axios.config'
const path = require('path')
const files = require.context('./modules', true, /\.js$/)
const generators = files.keys().map(key => ({
    name: path.basename(key, '.js'),
    func: files(key).default
}))

export default Object.assign({},
    ...generators.map(generator => ({
        [generator.name]: generator.func(request)
    }))
)